import React, { useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import ProductGrid from '../components/ProductGrid'
import './shop.scss'

const CollectionPage = ({ data }) => {
  const collection = data.shopifyCollection
  useEffect(() => {
    const cartQuantity = document.querySelector('.cart-quantity')
    cartQuantity.classList.remove('black')
  }, [])
  return (
    <div className="plp">
      <SEO title={collection.title} description={collection.description} />
      <div className="halves">
        <div className="border-gradient mobile" />
        <div className="left half">
          <h1 className="gradient-text-rotated u-h0">{collection.title}</h1>
        </div>
        <div className="border-gradient" />
        <div className="right half">
          <ProductGrid />
        </div>
      </div>
    </div>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        shopifyCollection(handle: { eq: "shop" }) {
          id
          title
          handle
        }
      }
    `}
    render={data => <CollectionPage data={data} {...props} />}
  />
)

CollectionPage.propTypes = {
  data: PropTypes.shape({
    shopifyCollection: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
