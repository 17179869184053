import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { gsap } from 'gsap'
import Image from 'gatsby-image'
import styled from 'styled-components'
import cart from '../../images/Cart.png'
import StoreContext from '~/context/StoreContext'
// import './styles.scss'
import Carousel from '../.base/carousel'

const MainWrapper = styled.div`
  height: 100%;
  .product-grid {
    .slick-list {
      padding-left: 0 !important;
    }
    .productItem {
      opacity: 0;
    }
    .product {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #30403c;
      margin-top: 25px;
      position: relative;

      .atc-icon {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        height: 50px;
        width: 50px;
        align-items: center;
        justify-content: center;
        background: #fff;
        .plus {
          background-color: #e9dccd;
          position: absolute;
          width: 12px;
          height: 12px;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          span {
            background-image: linear-gradient(154deg, #d38448 22%, #855131 80%);
            font-family: RobotoMono;
            font-size: 10px;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            position: absolute;
            top: -17.5px;
            right: 3px;
          }
        }
      }
      span {
        font-family: 'Roboto Mono';
        font-size: 12px;
        letter-spacing: -0.3px;
        font-weight: 300;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .gatsby-image-wrapper {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .product-grid {
      padding: 40px 0;
    }
    .productItem {
      padding: 0 20px;
    }
  }
  @media screen and (min-width: 768px) {
    .product-grid, .slick-list, .slick-track {
      height: 100%;
    }
    .slick-track {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;
      width: 90% !important;
      max-width: 500px;
      margin: auto;
      padding: 60px 0;
      transform: none !important;
      place-content: center;
      &:before, &:after {
        display: none;
      }
    }
    .slick-slide {
      width: 100% !important;
      float: none;      
    }
  }
`
const ProductGrid = () => {
  const {
    store: { checkout },
    addVariantToCart,
    openCart,
  } = useContext(StoreContext)
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: { fields: [createdAt], order: DESC }) {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              variants {
                price
                id
                shopifyId
              }
            }
          }
        }
      }
    `
  )

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  const handleATC = (variant, e) => {
    console.log(variant)
    console.log(e)
    e.preventDefault()
    addVariantToCart(variant.shopifyId, 1, [])
    openCart()
  }

  React.useEffect(() => {
    let items = document.querySelectorAll('.productItem')
    gsap.fromTo(
      items,
      {
        autoAlpha: 0,
        translateY: '-50px',
      },
      {
        autoAlpha: 1,
        translateY: '0',
        stagger: 0.3,
      }
    )
  }, [])

  const slickSettings = {
    draggable: false,
    infinite: false,
    slidesToShow: 1,
    settings: 'unslick',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '18%',
          infinite: false,
          slidesToShow: 1,
        }
      },
    ]
  }
  return (
    <MainWrapper>
      <Carousel classList="product-grid" settings={slickSettings}>
        {allShopifyProduct.edges ? (
          allShopifyProduct.edges
            .filter(({ node: { handle } }) => !handle.includes('subscription'))
            .map(
              ({
                node: {
                  id,
                  handle,
                  title,
                  images: [firstImage],
                  variants: [firstVariant],
                },
              }) => (
                <Link
                  className="productItem"
                  key={id}
                  to={`/products/${handle}/`}
                >
                  <div className="product">
                    {firstImage && firstImage.localFile && (
                      <Image
                        fluid={firstImage.localFile.childImageSharp.fluid}
                        alt={handle}
                      />
                    )}
                    <div
                      className="atc-icon"
                      onClick={e => handleATC(firstVariant, e)}
                    >
                      <img src={cart} alt="add to cart" />
                      <div className="plus">
                        <span>+</span>
                      </div>
                    </div>
                    <div className="d-flex flex-between align-center">
                      <span className="title">{title}</span>
                      <span className="price-tag gradient-text-rotated">
                        {getPrice(firstVariant.price)}
                      </span>
                    </div>
                  </div>
                </Link>
              )
            )
        ) : (
          <p>No Products found!</p>
        )}
      </Carousel>
    </MainWrapper>
  )
}

export default ProductGrid
