import React from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";

const defaultSettings = {
    dots: false,
    arrows: false,
}

const Carousel = ({
    classList,
    settings,
    children
}) => {
    settings = {
        ...defaultSettings,
        ...settings
    }
	return (
        <Slider
            className={classList}
            {...settings}
        >
            {children}
        </Slider>
	);
};

export default Carousel